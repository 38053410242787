header {
  position: relative;
  z-index: 1;
}

.bg-dark-blue {
  background-color: #0a1026;
}

.bg-ligt-blue {
  background-color: rgba(255, 255, 255, 0.08);
}

.bg-light-gray {
  background-color: #f3f4f8;
}

.mr-minus-base {
  margin-right: -20px;
}

.text-gray-black {
  color: #0a1026;
}

.border-gray-black {
  border-color: #0a1026;
}

.border-light-gray {
  border-color: #bdbec5;
}

.bg-dark-white {
  background-color: #bdbec5;
}

.bg-light-sm {
  background-color: #f6f6f6 !important;
}

.border-gray-shade {
  border-color: #cacaca;
}

.text-light-black {
  color: rgba(10, 16, 38, 0.8);
}

.bg-light-white {
  background-color: #fbfbfb;
}

.box-shadow-light {
  box-shadow: 54px 138px 59px rgba(0, 0, 0, 0.01),
    30px 78px 50px rgba(0, 0, 0, 0.02), 13px 35px 37px rgba(0, 0, 0, 0.03),
    3px 9px 20px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
}

.box-shadow-medium {
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.04);
}

.text-dark-black {
  color: #424349;
}

.bg-dark-black {
  background-color: #424349;
}

.bg-light-red {
  background-color: #ffeded;
}

.text-light-red {
  color: #852525;
}

.bg-light-yellow {
  background-color: #fff6bf;
}

.bg-light-green {
  background-color: rgba(39, 199, 25, 0.12);
}

.text-light-green {
  color: #27c719;
}

.bottom-minus-60 {
  bottom: -60px;
}

.text-light-white {
  color: rgba(255, 255, 255, 0.8);
}

.bg-black-transparent {
  background: rgba(155, 155, 155, 0.2);
}

.drop-down-menu {
  opacity: 0;
  visibility: hidden;
}

.drop-down:hover .drop-down-menu {
  opacity: 1;
  visibility: visible;
}

#dropdownMenu {
  display: none;
}

#dropdownMenu.active {
  display: block;
}

#sidemenu {
  display: none;
}

#sidemenu.active {
  display: block;
}

.swiper-button-prev,
.swiper-button-next {
  width: 64px;
  height: 64px;
}

.swiper-button-next:after {
  content: '';
  width: 64px;
  height: 64px;
  background-color: #f3da38;
  border-radius: 100%;
  background-image: url(assets/arrow-right.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

.swiper-button-prev:after {
  content: '';
  width: 64px;
  height: 64px;
  background-color: #f3da38;
  border-radius: 100%;
  background-image: url(assets/arrow-left.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

.bg-white-gray {
  background-color: #f3f4f8 !important;
}

.bg-dark-gray {
  background-color: rgba(0, 0, 0, 0.06);
}

.bg-shade-gray {
  background-color: #e0e0e0;
}

.bg-shade-gray-dark {
  background-color: #bdbec5;
}

.text-dark-white {
  color: #bdbec5;
}

.side-nav ul li svg {
  /* stroke: #fff; */
}

.side-nav ul li a {
  /* color: #fff; */
}

.side-nav ul li.active {
  border: 0.5px solid #9198BC;
  background-color: #E4EDFA;
}

.sideBarNav.active {
  border: 0.5px solid #9198BC !important;
  background-color: #E4EDFA !important;
}

.sideBarNav.active a {
  color: #0a1026 !important;
  /* background-color: #f3da38; */
}

.sideBarNav.active a svg path {
  fill: #0a1026;
}


.side-nav ul li.active a svg path {
  fill: #0a1026;
  /* stroke: #f3da38; */
}

.side-nav ul li.active .assessment {
  /* stroke: #0a1026; */
}

.border-color-d2 {
  border-color: #d2d2da;
}

.side-nav ul li.active a {
  color: #0a1026;
  /* background-color: #f3da38; */
}

.search-bar {
  max-width: 500px;
}

.search-bar input {
  border-radius: 63px;
  padding-left: 54px;
  color: #323554;
}

.search-bar input::placeholder {
  color: #323554;
}

.dasboard-content {
  /* margin-top: 104px; */
  /* margin-left: 15rem; */
  margin-left: 226px;
}

.progress-bar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: radial-gradient(closest-side, white 79%, transparent 80% 100%),
    conic-gradient(#f2184c 75%, #e3e5eb 0);
}

.setup-checkbox {
  background-image: url(assets//checkbox-minus.svg) !important;
  background-repeat: no-repeat;
  background-color: #ececec !important;
  background-size: 20px !important;
  background-position: center center;
}

.setup-checkbox:hover,
.setup-checkbox:focus {
  background-color: #ececec !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.setup-checkbox:checked {
  background-color: rgba(74, 201, 30, 0.1) !important;
  background-image: url(assets/check-icon-green.png) !important;
  background-repeat: no-repeat;
  background-size: 20px 14px !important;
  background-position: center center;
}

.setup-checkbox:checked:hover,
.setup-checkbox:checked:focus {
  background-color: rgba(74, 201, 30, 0.1);
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.16);
}

.answer-checkbox:focus,
.answer-checkbox:checked:focus {
  box-shadow: none !important;
}

.answer-checkbox:checked {
  background-color: #f3da38 !important;
  outline-color: #f3da38 !important;
  background-image: url(assets/check-icon-black.svg) !important;
  background-size: 15px 14px !important;
}

.border-light-black {
  border-color: #e0e0e0;
}

.play-btn {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(9px);
}

.answer-check-sm:checked {
  background-size: 12px 12px !important;
}

.bg-gray-transparent {
  background-color: rgba(0, 0, 0, 0.3);
}

#jodDetailsOverlay {
  width: 0px;
}

#jodDetailsOverlay.active {
  width: 100%;
}

#jodDetails {
  max-width: 825px;
  right: -825px;
}

#jodDetails.active {
  right: 0;
}

.bg-white-sm-light {
  background-color: #f6f6f6;
}

@media (max-width: 992px) {
  .dasboard-content {
    /* margin-left: 0px; */
  }

  .side-nav {
    left: -15rem;
    transition: all 0.3s ease-in-out;
  }

  .side-nav.active {
    left: 0;
    transition: all 0.3s ease-in-out;
  }
}

@media (max-width: 767px) {
  .search-bar input {
    padding-left: 36px;
  }

  .dasboard-content {
    /* margin-top: 72px; */
  }
}

@media (max-width: 580px) {
  .bottom-minus-60 {
    bottom: -38px;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    width: 40px;
    height: 40px;
    background-size: 18px;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }
}

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.top-box {
  max-width: 210px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.bottom-box {
  max-width: 210px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.left-box {
  display: flex;
  align-items: center;
  position: absolute;
  left: 12%;
  top: 50%;
  transform: translateY(-50%);
}

.right-box {
  display: flex;
  align-items: center;
  position: absolute;
  right: 12%;
  top: 50%;
  transform: translateY(-50%);
}

.side-box {
  width: 210px;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.no-tailwind * {
  all: initial;
}



/* new dashboard design css start */

.GlobalRanking {
  background: #161B2A;
}

.blue-gradient {
  background: #161B2A;
}

.red-gradient {
  background: #161B2A;
}

.purple-gradient {
  background: #161B2A;
}

.green-gradient {
  background: #161B2A;
}

.text-nowrap {
  text-wrap: nowrap;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.card-box-shadow {
  box-shadow: 0px 1.68px 4.19px 0px #26334D08;
}

.border-box {
  border-bottom: 1.68px solid #F2F2F2
}

.pointer circle {
  cy: -10;
}

.singleLineText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

/* new dashboard design css end */

/* new report design css start */

#ExternalData .externalBox:first-child {
  background-color: #21b42f;
}

#ExternalData .externalBox:nth-child(2) {
  background-color: #8833ff;
}

#ExternalData .externalBox:nth-child(3) {
  background-color: #ff6633;
}

#ExternalData .externalBox:nth-child(4) {
  background-color: #33bfff;
}

#ExternalData .externalBox:nth-child(5) {
  background-color: #1a2233;
}

.internalDataCard {
  background-image: url(./assets/new-dashboard-img/map.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;

  background-position: center right;
}

.banner-img {
  background-image: url(./assets/banner-img.png);
  position: relative;
}

.banner-img::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.792754) 41.15%, #141414 60.08%);
}

/* new report design css end */

.swiper-slide-thumb-active img {
  opacity: 1 !important;
}

.productSilder {
  padding-bottom: 100px;
}

.productSilder .swiper-pagination {
  background-color: #141414;
  display: inline-flex;
  width: 360px;
  padding: 36px 70px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 40px;
  bottom: 0;
}

.productSilder .swiper-pagination-bullet {
  height: 4px;
  width: 17px;
  background-color: #4D4D4D;
  border-radius: 6px;
}

.productSilder .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #703BF7;
}

.productSilder .swiper-button-next:after {
  border: 1px solid #262626;
  background-color: #1A1A1A;
  background-image: url(assets/product-img/arrow-right.svg);
  width: 60px;
  height: 60px;
}

.productSilder .swiper-button-next {
  bottom: 6px;
  top: auto;
  right: 35%;
  z-index: 99;
}

.productSilder .swiper-button-prev:after {
  border: 1px solid #262626;
  background-color: #1A1A1A;
  background-image: url(assets/product-img/arrow-left.svg);
  width: 60px;
  height: 60px;
}

.productSilder .swiper-button-prev {
  bottom: 6px;
  top: auto;
  left: 35%;
  z-index: 99;
}

@media(max-width:1280px) {
  .productSilder .swiper-pagination {
    width: 280px;
  }
}

@media(max-width:992px) {
  .productSilder .swiper-button-next {
    right: 29%;
  }

  .productSilder .swiper-button-prev {
    left: 29%;
  }
}

@media(max-width:767px) {
  .productSilder .swiper-button-next {
    right: 21%;
  }

  .productSilder .swiper-button-prev {
    left: 21%;
  }
}

@media(max-width:480px) {
  .productSilder .swiper-pagination {
    padding: 30px 50px;
  }

  .productSilder .swiper-button-next:after,
  .productSilder .swiper-button-prev::after {
    width: 50px;
    height: 50px;
  }

  .productSilder {
    padding-bottom: 80px;
  }

  .productSilder .swiper-button-next {
    right: 6%;
    bottom: 1px;
  }

  .productSilder .swiper-button-prev {
    left: 6%;
    bottom: 1px;
  }
}

/* new home baner css */

.bg-orgnigtion {
  background: linear-gradient(0deg, #EBF5FF, #EBF5FF);
  padding-top: 123px;

  @media (max-width:1020px) {
    padding-top: 48px;
    margin-top: 65px;
  }
}

.bg-orgnigtion1 {
  background: linear-gradient(178.74deg, #E8F4FF 64.1%, #407BFF 133.14%);
}

.circle-box {
  transform: translate(-50%, -50%);
  background: #ebf5ff;
}

.circle-box svg {
  margin-top: -52px;
}


.box-border-image {
  border: 5px solid;
  border-image-source: linear-gradient(90deg, #FFFFFF 0%, #407BFF 52.5%, #264A99 100%);
  border-image-slice: 1;
  border-left: 0px;
  border-right: 0px;
}

.card-border {
  /* background-image: url(./assets/new-home/Rectangle\ 1891598830.png); */
  background-color: #EBF5FF;
  border-radius: 6px;
  background-size: 100% 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  width: 388px;
  height: 388px;

  @media (max-width:768px) {
    width: 100%;
    height: 100%;
  }
}

.blue-side-image {
  /* background-image: url(./assets/new-home/Ellipse\ 1965.svg); */
  background-size: 100% 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  width: 96px;
  height: 96px;
  background-color: #407BFF;
  border-radius: 100px 0px 8px 0px;

  @media (max-width:768px) {
    width: 70px;
    height: 70px;
  }
}

/* new home babber css */

.ff-inter {
  font-family: "Inter", sans-serif;
}

.ff-mulish,
.font-mulish {
  font-family: "Mulish-Regular", sans-serif;
}

.ff-pop,
.font-poppins {
  font-family: "Poppins-Regular", sans-serif;
}


@font-face {
  font-family: 'Degular Light';
  src: url('./assets/new-home/Degular-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Degular Thin';
  src: url('./assets/new-home/Degular-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'NeueMachina-Ultrabold';
  src: url('./assets/new-home/NeueMachina-Ultrabold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

/* Poppins Font - Regular */
@font-face {
  font-family: 'Poppins-Regular';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: url('./assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

/* Poppins Font - Bold */
@font-face {
  font-family: 'Poppins-Bold';
  src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* Mulish Font - Regular */
@font-face {
  font-family: 'Mulish-Regular';
  src: url('./assets/fonts/Mulish-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish-Medium';
  src: url('./assets/fonts/Mulish-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish-Bold';
  src: url('./assets/fonts/Mulish-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish-ExtraBold';
  src: url('./assets/fonts/Mulish-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}


/* Mulish Font - SemiBold */
@font-face {
  font-family: 'Mulish-SemiBold';
  src: url('./assets/fonts/Mulish-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

/* Now, you can apply it to your elements */

.ff-degular {
  font-family: 'Degular Thin', sans-serif;
}

.ff-NM {
  font-family: 'NeueMachina-Ultrabold', sans-serif;
}

.bg-cutomer-slider-1 {
  /* background-image: url('./assets/new-home/bg-light-1.png'); */
  /* background-size: 100% 100%;
  background-position: bottom center;
  background-repeat: no-repeat; */
  width: 387px;
  border-radius: 10px;
  background-color: #EBF5FF;
  /* height: 297px; */
  border: #407BFF 2px solid;

  @media (max-width:768px) {
    width: 100%;
    /* height: 250px; */
  }
}

.bg-cutomer-slider-2 {
  /* background-image: url('./assets/new-home/bg-blue-2.png'); */
  /* background-size: 100% 100%;
  background-position: bottom center;
  background-repeat: no-repeat; */
  width: 387px;
  /* height: 297px; */
  border-radius: 10px;
  background-color: #407BFF;
  border: #407BFF 2px solid;

  @media (max-width:768px) {
    width: 100%;
    /* height: 250px; */
  }
}

.bg-contect {
  background-image: url('./assets/new-home/contect.png');
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 412px;

  @media (max-width:768px) {
    background-image: url('./assets/new-home/Frame\ 1261155464.png');
    height: 160px;
  }
}

.bg-efficient {
  background-image: url('./assets/new-image/dark-blue-box.png');
  background-size: 100% 100%;
  background-position: left top;
  background-repeat: no-repeat;
}

.service-1 {
  /* background-image: url('./assets/new-home/Rectangle\ 1891598828.png'); */
  background-size: 100% 100%;
  border-radius: 8px;
  border: #407BFF 2px solid;
  background-color: #407BFF;
  background-position: left top;
  background-repeat: no-repeat;
}

.service-2 {
  /* background-image: url('./assets/new-home/Rectangle\ 18915988302.png'); */
  background-size: 100% 100%;
  border-radius: 8px;
  border: 3px solid;

  border: #407BFF 2px solid;
  background-color: #EBF5FF;
  background-position: left top;
  background-repeat: no-repeat;
}


.industry-card {
  /* background-image: url('./assets/new-home/Card.png'); */
  border: #407BFF 2px solid;
  background-color: white;
  border-radius: 10px;
  background-size: 100% 100%;
  background-position: left top;
  background-repeat: no-repeat;
}

.bg-dark-footer {
  background-image: url('./assets/new-image/footer.png');
  background-size: 100% 100%;
  background-color: #061927;
  background-position: left top;
  background-repeat: no-repeat;
}

.accordian.open svg {
  transform: rotate(45deg);
}

.mys-wipe .swiper {
  height: 450px;

  @media (max-width:768px) {
    height: 400px;
  }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 15px;
}

.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  background: #D9D9D9;
  opacity: 1;

  @media (max-width:768px) {
    width: 8px;
    height: 8px;
  }
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: white;
  border: #D9D9D9 1px solid;
}


/* certificate css */

@media print {
  @page {
    size: 50mm 71mm;
  }

  #number-text {
    margin-top: 45px;
    margin-left: 240px;
    font-size: 18px !important;
    text-align: center;
    font-family: 'Podkova-Regular' !important;
  }


  #font-custom-size,
  #font-custom-size2 {
    font-size: 48px !important;
    line-height: 0.9 !important;
    font-family: 'Podkova-Regular' !important;
  }

  #name-text-small,
  #name-text-small1,
  #name-text-small2 {
    font-size: 30px !important;
    font-family: 'Podkova-Regular' !important;
  }

  #paragraph {
    font-family: 'Podkova-Regular' !important;
    font-size: 30px !important;
  }
}

.table-main-wrapper {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

header {
  /* display: none !important; */
}

.hero-section,
.content-section,
.score-section,
.Assessment-section {
  page-break-inside: avoid;
}

/* Hide Chrome's default header/footer when printing */
@page {
  margin: 0;
}

@page :first {
  margin-top: 0;
}

@page :left {
  margin-left: 0;
}

@page :right {
  margin-right: 0;
}


.table-main-wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: 1180px;
  font-family: 'Podkova-Regular' !important;
  margin: auto;
}

.table-main-wrapper p {
  font-family: 'Podkova', serif;
  text-align: center;
  margin: 0;
  white-space: nowrap;
  font-size: 25px !important;
  font-family: 'Podkova-Regular' !important;
}

.name-text {
  top: 40% !important;
  left: 50% !important;
  transform: translateX(-50%);
  font-size: 35px !important;
  position: absolute;
  text-align: center;
  width: 100%;
}

.name-text span {
  display: block !important;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 5px !important;
  /* Added spacing between spans */
}

.name-text-small {
  font-size: 25px;
  font-family: 'Podkova-Regular' !important;
}

.green-text {
  color: green !important;
}

.number-text {
  margin-top: 130px !important;
  font-size: 20px !important;
  margin-left: 200px !important;
  text-align: center !important;
  font-family: 'Podkova-Regular' !important;
}

.font-custom-size {
  font-size: 48px !important;
  line-height: 0.9 !important;
  font-family: 'Podkova-Regular' !important;
}

@font-face {
  font-family: 'Podkova-Regular';
  src: url('./assets/Podkova-Regular.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

/* certificate css */

.slider-preview .swiper-wrapper {
  display: flex !important;
  height: 100% !important;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .myclass {
    transform: rotateY(180deg);
  }

  .bg-none-1 {
    background-image: none !important;
  }

}

@keyframes marquee {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

.animate-marquee {
  display: flex;
  animation: marquee 80s linear infinite;
}


.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
