
@font-face {
  font-family: raleway;
  font-weight: 300;
  src: url(https://third-bracket.s3.eu-north-1.amazonaws.com/pdf/Raleway-Light.ttf);
}
@font-face {
  font-family: raleway;
  font-weight: 400;
  src: url(https://third-bracket.s3.eu-north-1.amazonaws.com/pdf/Raleway-Regular.ttf);
}
@font-face {
  font-family: raleway;
  font-weight: 500;
  src: url(https://third-bracket.s3.eu-north-1.amazonaws.com/pdf/Raleway-Medium.ttf);
}
@font-face {
  font-family: raleway;
  font-weight: 600;
  src: url(https://third-bracket.s3.eu-north-1.amazonaws.com/pdf/Raleway-SemiBold.ttf);
}
@font-face {
  font-family: raleway;
  font-weight: 700;
  src: url(https://third-bracket.s3.eu-north-1.amazonaws.com/pdf/Raleway-Bold.ttf);
}
@font-face {
  font-family: raleway;
  font-weight: 800;
  src: url(https://third-bracket.s3.eu-north-1.amazonaws.com/pdf/Raleway-ExtraBold.ttf);
}
@font-face {
  font-family: raleway;
  font-weight: 900;
  src: url(https://third-bracket.s3.eu-north-1.amazonaws.com/pdf/Raleway-Black.ttf);
}
@font-face {
  font-family: NeueMachina-Ultrabold;
  font-weight: 900;
  src: url('../../assets/pdf/NeueMachina-Ultrabold.otf');
}

.font-NeueMachina{
  font-family: NeueMachina-Ultrabold;
  font-weight: 900;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');

.m-normal{
font-family: Montserrat;
font-weight: 400;
}
.m-bold{
font-family: Montserrat;
font-weight: 600;
}

body {
  margin: 0%;
  padding: 0%;
}
td{
  font-size: 14px;
}
* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: raleway;
}
img {
  height: auto;
}
.pr {
  position: relative;
}
.w-100 {
  width: 100%;
}
.h-auto {
  height: auto;
}
img{
  height: 100%;
}
.h-100 {
  height: 100%;
}

.m-0 {
  margin: 0%;
}

ul,
ol {
  padding-left: 20px;
}

.section-padding {
  padding-top: 70px;
  padding-left: 80px;
  padding-right: 80px;
  /* padding-left: 80px; */
  /* padding-right: 80px; */
}

.section {
  /* min-height: 70vh; */
}

.container {
  width: 1200px;
  max-width: 100%;
  /* padding: 0 15px; */
  margin: 0 auto;
}

.section-heading {
  font-size: 36px;
  font-weight: 900;
  margin: 0 0 15px;
  color: #0d1a48;
  line-height: 1;
}

.blue-text {
  color: #305eff;
}

.section-desc,
ul li,
ol li {
  font-size: 22px;
  color: #192839;
  font-weight: 500;
}
.skill-profiling-item-title,.skill-profiling-item-desc{
font-size: 22px;
}
.fw-700 {
  font-weight: 700;
}

.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}


/* hero section css start */
.hero-section .hero-content-wrapper {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  padding: 50px 15px;
}

.hero-section .hero-content-wrapper .section-heading {
  color: #99c1da;
}

.hero-section .hero-logo {
  width: 200px;
}

/* hero section css end */

/* content-section css start */
.content-section .content-item-wrapper::after {
  position: absolute;
  content: "";
  bottom: 0%;
  left: 0%;
  width: 70%;
  height: 1px;
  background-color: #b4b4b4;
}

.content-section .content-item-wrapper::before {
  position: absolute;
  content: "";
  bottom: -3px;
  left: calc(70% - 5px);
  width: 7px;
  height: 7px;
  background-color: #b4b4b4;
  transform: rotate(45deg);
}

.content-section .content-item-wrapper .content-item {
  font-size: 28px;
  font-weight: 600;
  color: #7f7e7e;
  padding: 25px 15px 6px;
}
.content-section .content-item-wrapper .content-item span{
  margin-right: 10px;
}

/* content-section css end */

/* skill-profiling-section css start */
.skill-profiling-section .skill-profiling-item {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.skill-profiling-section .skill-profiling-item .skill-profiling-item-img {
  width: calc(35% - 10px);
}

.skill-profiling-section
  .skill-profiling-item
  .skill-profiling-item-info {
  width: calc(65% - 10px);
}

/* skill-profiling-section css end */

/* info-section css start */
.info-section-img {
  /* width: 10; */
  /* height: 80vw; */
  margin: 20px auto 0;
  max-width: 100%;
}
.info-section-img-zxx{
   height: 80vw;
   margin: 20px auto 0;
   max-width: 100%;
}
.info-section-img-zx{
  height: 100vw;
  margin: 20px auto 0;
  max-width: 100%;
} 
.info-section-img-2{
  width: 100%;
  height: 500px;
  margin: 40px auto 0;
  max-width: 100%;
}

/* info-section css end */

/* score-section css start */
.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.progress-bar::before {
  content: attr(data-val);
  font-size: 22px;
  font-weight: 700;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.score-section .score-section-wrapper {
  gap: 50px;
}

.score-section .score-section-wrapper .competency-block,
.score-section .competency-block-item .competency-block-item-title {
  width: 50%;
  
}

.score-section .score-section-wrapper .score-block,
.score-section .competency-block-item img {
  width: 40%;
}

.score-section .competency-block-item img {
  padding-right: 20px;
}

.score-section .competency-block-item {
  margin: 10px 0;
  /* height: 200px; */
}

.score-section .competency-block-item img{
  max-height: 150px;
  object-fit: contain;
}
.score-section .block-title {
  font-size: 26px;
}

/* score-section css end */

/* footer css start */
footer .footer-logo {
  width: 150px;
  position: absolute;
  top: 50px;
  right: 50px;
}

footer .footer-content-wrapper {
  position: absolute;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 15px;
}

footer .footer-title {
  font-size: 26px;
  color: #fff;
  font-weight: 700;
}

footer .section-desc {
  color: #cfcfcf;
  margin: 4px 0;
}

footer .contact-detail {
  color: #89ae1d;
  font-size: 22px;
  font-weight: 700;
}

/* footer css end */

@media (max-width: 768px) {
  .progress-bar {
    width: 100px;
    height: 100px;
  }

  .hero-section .hero-content-wrapper {
    padding: 30px 15px;
  }

  .section-heading {
    font-size: 36px;
  }

  .hero-section .hero-logo-wrapper {
    margin-bottom: 20px;
  }

  .hero-section .hero-logo {
    width: 140px;
  }


  .section {
    min-height: 90vh;
  }
}

@media (max-width: 560px) {
  .content-section .content-item-wrapper::after {
    width: 85%;
  }

  .content-section .content-item-wrapper::before {
    left: calc(85% - 5px);
  }

  .section-heading {
    font-size: 36px;
  }

  .hero-section .hero-logo {
    width: 85px;
  }
}
.h-screen {
  height: 100vh;
}
.page-break {
  page-break-before: always;
}

.pdf-content {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
}

.centered-image {
  text-align: center;
  padding: 10px 0;
  page-break-inside: avoid;
}

.centered-image img {
  width: 90%;
  max-width: 600px;
  height: auto;
  max-height: 400px;
  margin: auto;
}
  
.hero-section {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height for first page */
  overflow: hidden;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire section */
  z-index: -1; /* Sends the background image behind the content */
}

.hero-content-wrapper {
  position: absolute;
  top: 20%;
  left: 5%;
  width: 90%; /* Control the width to prevent overflow on smaller screens */
}

.hero-logo-wrapper {
  position: absolute;
  top: 20px;
  right: 20px; /* Adjust these values to position the logo */
}

.hero-heading {
  margin-top: 40px; 
  font-size: 3em;
  color: #FFFFFF !important;
  text-align: left !important;
}

@media screen and (max-width: 768px) {
  .hero-heading {
    font-size: 2em; /* Adjust font size for smaller screens */
  }
}

.assessment-section {
  width: 100%;
  padding: 0; /* Remove padding to let the image fill the space */
}

.section-heading {
  margin-bottom: 10px; /* Adjust as needed */
  text-align: center; /* Center the heading */
}

.full-width-image {
  width: 100%;
  display: flex;
  justify-content: center;
}

.full-width-image img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain; /* Ensures the image scales to fit without stretching */
}
.score-progress-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media print {
  @page {
    /* margin: 0; */
    size: auto;
  }

  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  header {
    display: none !important;
  }

  .hero-section, .content-section, .score-section, .Assessment-section {
    page-break-inside: avoid;
  }

  /* Hide Chrome's default header/footer when printing */
  @page {
    margin: 0;
  }

  @page :first {
    margin-top: 0;
  }

  @page :left {
    margin-left: 0;
  }

  @page :right {
    margin-right: 0;
  } 
}

tbody li,
tbody p,tbody span,tbody h3,tbody h2,tbody h4,tbody h5,tbody h6,tbody h1{
  font-size: 20px !important;
  color: #192839 !important;
  font-weight: 500 !important;

}
.custom-bg-new{
  background-image: url('../../assets/pdf/image7.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height:100vh;

}

/* @page  {
  background-image: url('../../assets/pdf/image7.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height:100vh;
} */

.text-bold{
  font-weight: 700;
}


